import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {getApp} from './api/firebase.utils';
import {useBreakpointValue, Box, Link, Text, Image, Icon, Heading, Button} from 'native-base';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FaInstagram, FaSquareFacebook, FaSquareTwitter, FaCircleXmark } from "react-icons/fa6";
import { isIOS, isMobile } from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import FooterAppPage from './FooterAppPage';

// Import Swiper styles
import 'swiper/css';

function SocialShareButton() {

  return null;/* (
    <div className='shareContent'>
      <button class="view-modal">Share</button>
      <div class="popup">
        <header>
          <span>Share Modal</span>
          <div class="close"><i class="uil uil-times"></i></div>
        </header>
        <div class="content">
          <p>Share this link via</p>
          <ul class="icons">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-whatsapp"></i></a>
            <a href="#"><i class="fab fa-telegram-plane"></i></a>
          </ul>
          <p>Or copy link</p>
          <div class="field">
            <i class="url-icon uil uil-link"></i>
            <input type="text" readonly value="https://codepen.io/coding_dev_" />
            <button>Copy</button>
          </div>
        </div>
      </div>
    </div>
  );*/
}
function AppPage(props) {
  const {t, i18n} = useTranslation();
  const [isShowBanner, setShowBanner] = useState(true);
  const flexDir = useBreakpointValue({
    base: "column",
    lg: "row"
  });
  const flex = useBreakpointValue({
    base: null,
    lg: 1
  });
  const alignIcon = useBreakpointValue({
    base: 'center',
    lg: 'flex-start',
  });

  console.warn('flex', flex);
  const categorie = t('APP_SCREEN.categorie').split(',');

  const [themeColor, setThemeColor] = useState('trueGray');
  const [dataApp, setDataApp] = useState(null);
  const [isLoad, setLoad] = useState(true);
  let { appName } = useParams();
  const navigate = useNavigate();
  console.warn(appName);

  function color(gradient){
    return `${themeColor}.${gradient}`
  }

  async function init(){
    setLoad(true);
    const data = await getApp(appName);

    if(data.length) {
      setDataApp(data[0]);
      document.title = data[0].app_name.toString();
      // console.log("Document title: ", document.title);
      const favIcon = document.getElementById("favicon");
      favIcon.href = data[0].iconApp[0];
    } else {
      navigate('/404');
    }
    setLoad(false);
  }
  
  useEffect(() => {
    console.warn('ok redirect', isIOS);
    init();
  }, []);


  function getCategorie(id) {
    return categorie.map((index) => {
      if (index.split(':')[0]==id) {
        return index.split(':')[1];
      }
    })
  }

  return isLoad ? <Box></Box> :(
    <Box flex={flex} bg={color('900')}>
      {(isMobile && isShowBanner )&& <Box bg={color(700)} padding={2} width={'100%'} flexDirection={'row'} alignItems={'center'}>
        <Box>
          <Button bg={'transparent'} onPress={() => setShowBanner(false)}>
            <Icon size={6}  color={color('200')}>
              <FaCircleXmark size={23} />
            </Icon>
          </Button>
        </Box>
        <Box ml={2} flex={1} flexDirection={'row'}>
          <Image borderRadius={22} source={{
            uri:dataApp.iconApp[0]
          }} alt="Alternate Text" size="md" />
          <Box ml={3}>
            <Text isTruncated maxW="40" fontSize={'22'} fontWeight={'medium'}  color={color('200')}>{dataApp.app_name}</Text>
            <Text fontSize={'16'} color={color('300')}>{getCategorie(dataApp.categorie)}</Text>
            <Text fontSize={'18'} fontWeight={'medium'} color={color('100')}>{dataApp.price===1 ? t('APP_SCREEN.price.button1') : t('APP_SCREEN.price.button2')}</Text>
          </Box>
        </Box>
        <Link _text={{
        color: color('200')
      }} href={isIOS ? dataApp.link_ios : dataApp.link_android} color={color('200')} bg={'transparent'}>{t('APP_SCREEN.button_show_banner')}</Link>
      </Box>}
      <Box flex={1} flexDirection={flexDir}>
        <Box flex={flex} justifyContent={'center'} padding={4}>
          <Box alignItems={alignIcon}>
            <Image mb={4} borderRadius={32} source={{
              uri:dataApp.iconApp[0]
            }} alt="Alternate Text" size="xl" />
          </Box>
          <Box bg={color('800')} borderRadius={'32'} padding={6}>
            <Heading fontSize={'40'} color={color('200')}>{dataApp.app_name}</Heading>
            <Text mt={1} fontSize={'20'} fontWeight={'medium'} color={color('100')}>{dataApp.price===1 ? t('APP_SCREEN.price.button1') : t('APP_SCREEN.price.button2')}</Text>
            <Text fontSize={'18'} fontWeight={'bold'} color={'warning.500'}>{getCategorie(dataApp.categorie)}</Text>
            <Text mt={1} fontSize={'20'} color={color('300')}>{dataApp.description}</Text>

            <Box flexDirection={flexDir} mt={4}>
              {dataApp.link_ios && <Link mb={flexDir==='row' ? 0 : 3} href={dataApp.link_ios} bg={'transparent'} justifyContent={'center'}>
                <Image ml={flexDir==='row' ? -3 : 0} source={require('./images/app-store-badge-en.png')} width={200} height={200/(498/148)} />
              </Link>}
              {dataApp.link_android && <Link href={dataApp.link_android} bg={'transparent'} justifyContent={'center'}>
                <Image source={require('./images/google-play-badge-en.png')} width={200} height={200/(498/148)} />
              </Link>}
            </Box>
          </Box>
          <Box flexDirection={'row'} mt={4} justifyContent={'center'}>
            {
              [dataApp.link_fb,
                dataApp.link_insta,
                dataApp.link_twitter].map((index, key) => {

                  if (index!=='' && key===0) {
                    return <Link href={index} isExternal><Icon size={12} color={color(200)}><FaSquareFacebook size={'36'} /></Icon></Link>;
                  } else if (index!=='' && key===1) {
                    return <Link href={index} isExternal><Icon size={12} color={color(200)}><FaInstagram size={'36'} /></Icon></Link>;
                  } else if (index!=='' && key===2) {
                    return <Link href={index} isExternal><Icon size={12} color={color(200)}><FaSquareTwitter size={'36'} /></Icon></Link>;
                  } else return null;
                })
            }
          </Box>
        </Box>
        <Box flex={flex} justifyContent={'center'} alignItems={'center'}>
          <Swiper
            style={{position:'absolute', backgroundColor:"#000", width:'270px',
            overflow:'hidden',
            borderRadius:25,
            height:270/(1179/2556)+'px', zIndex:1}}
            spaceBetween={0}
            autoplay={{
              delay: 2500
            }}
            speed={1800}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {dataApp.screenCapture.map((index, key) => {
              return (
                <SwiperSlide>
                  <Image source={{uri: index}} width={'100%'} height={'100%'} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <Image zIndex={2} source={require('./images/iphone14Pro.png')} width={300} height={300/(1849/3762)} />
        </Box>
      </Box>
      <FooterAppPage {...{color: color, dataApp: dataApp}} />
    </Box>
  );
}

export default AppPage;

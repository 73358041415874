import './App.css';
import {useBreakpointValue, Box, Link, Text, Image, Icon, Heading, Button} from 'native-base';
import {useTranslation} from 'react-i18next';

function FooterAppPage(props) {
  const {dataApp, color} = props;
  const {t, i18n} = useTranslation();
  const flexDir = useBreakpointValue({
    base: "column",
    lg: "row"
  });

  return (
    <Box mt={12} bg={color('800')} padding={3} flexDirection={flexDir}>
      <Text textAlign={flexDir==='column' ? 'center' : 'left'} flex={1} color={color('300')}>© {(new Date()).getFullYear()}  - {dataApp.app_name}</Text>
      <Box flexDirection={'row'} justifyContent={'center'}>
        {dataApp.legal_notice && dataApp.legal_notice.length>0 && <Link href={`/app/${dataApp.url_app_name}/legal-notice`} ml={2} _text={{
          color:color('400')
        }}>
          {t('APP_SCREEN.legal_notice')}
        </Link>}
        {dataApp.privacy_policy && dataApp.privacy_policy.length>0 && <Link href={`/app/${dataApp.url_app_name}/privacy-policy`}ml={4} _text={{
          color:color('400')
        }}>
          {t('APP_SCREEN.privacy_policy')}
        </Link>}
      </Box>
    </Box>
  );
}

export default FooterAppPage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppPage from './AppPage';
import reportWebVitals from './reportWebVitals';
import { NativeBaseProvider, Text, Box } from "native-base";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NotFound from './notFound';
import LegalPage from './LegalPage';
import i18n from './api/translation';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
  },
  {
    path: "/app/:appName",
    element: <AppPage />,
  },
  {
    path: "/app/:appName/:legalPage",
    element: <LegalPage />,
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <NativeBaseProvider>
      <RouterProvider router={router} />
    </NativeBaseProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {getApp} from './api/firebase.utils';
import {useBreakpointValue, Box, Link, Text, Image, Icon, Heading, Button} from 'native-base';
import { isIOS, isMobile } from 'react-device-detect';
import FooterAppPage from './FooterAppPage';
import {useTranslation} from 'react-i18next';

function LegalPage(props) {
  const {t, i18n} = useTranslation();
  const pageType = [{id:'legal-notice', trad: t('APP_SCREEN.legal_notice')}, {id: 'privacy-policy', trad: t('APP_SCREEN.privacy_policy')}];
  const [pageTitle, setPageTitle] = useState('');
  const flexDir = useBreakpointValue({
    base: "column",
    lg: "row"
  });
  const flex = useBreakpointValue({
    base: null,
    lg: 1
  });
  const alignIcon = useBreakpointValue({
    base: 'center',
    lg: 'flex-start',
  });

  const [themeColor, setThemeColor] = useState('trueGray');
  const [dataApp, setDataApp] = useState(null);
  const [isLoad, setLoad] = useState(true);
  let { appName, legalPage } = useParams();
  const navigate = useNavigate();
  console.warn(appName);

  function color(gradient){
    return `${themeColor}.${gradient}`
  }

  async function init(){
    const pageId = pageType.findIndex((data) => {return data.id===legalPage});

    // check before if page type exist
    if(pageId!==-1) {
      setPageTitle(pageType[pageId]);
    } else {
      navigate('/404');
    }
    setLoad(true);
    const data = await getApp(appName);

    if(data.length) {
      setDataApp(data[0]);
      document.title = data[0].app_name.toString();
      // console.log("Document title: ", document.title);
      const favIcon = document.getElementById("favicon");
      favIcon.href = data[0].iconApp[0];
    } else {
      navigate('/404');
    }
    setLoad(false);
  }
  
  useEffect(() => {
    console.warn('ok redirect', isIOS);
    init();
  }, []);

  return isLoad ? <Box></Box> :(
    <Box bg={color('900')}>
      <Box flexDirection={flexDir} justifyContent={'center'}>
        <Box padding={4} alignItems={'center'}>
          <Box alignItems={'center'}>
            <Link href={`/app/${dataApp.url_app_name}`}>
              <Image mb={4} borderRadius={32} source={{
                uri:dataApp.iconApp[0]
              }} alt="Alternate Text" size="xl" />
            </Link>
            <Heading fontSize={'45'} color={color('200')}>{dataApp.app_name}</Heading>
            <Heading mt={3} textAlign={'center'} fontSize={'30'} color={color('400')}>{pageTitle.trad}</Heading>
          </Box>
          <Box mt={4} bg={color('800')} borderRadius={'32'} padding={6}>
            {pageTitle.id==='legal-notice' && <div className="contentLegal" dangerouslySetInnerHTML={{__html: dataApp.legal_notice}} /> }
            {pageTitle.id==='privacy-policy' && <div className="contentLegal" dangerouslySetInnerHTML={{__html: dataApp.privacy_policy}} />}
          </Box>
        </Box>
      </Box>
      
      <FooterAppPage {...{color: color, dataApp: dataApp}} />
    </Box>
  );
}

export default LegalPage;

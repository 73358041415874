// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, query, where, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfvxvcPmSSQ5-GzQF6hLImVmyosYGOfuY",
  authDomain: "appodiz.firebaseapp.com",
  databaseURL: "https://appodiz-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "appodiz",
  storageBucket: "appodiz.appspot.com",
  messagingSenderId: "305468034274",
  appId: "1:305468034274:web:3a065c632f4acab17ae2bb",
  measurementId: "G-V64Q0848FD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database
export async function getApp(appName) {
    const q = query(collection(db, "apps"), where("url_app_name", "==", appName));
    const data = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data.push(doc.data());
    });
    console.warn('ok cityList= ', data);
    return data;
}
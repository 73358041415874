import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// import {isArray} from 'lodash';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      HOME_SCREEN: {
        HELLO_APP: 'Testing app',
        FAVORIS_TITLE: 'Favoris',
        HISTORY_TITLE: 'Mes sites',
        EMPTY_HISTORY_:
          "Pour le moment, il n'y a pas de site. Creer votre 1er site pour commencer.",
        LOGOUT_BUTTON: 'Deconnexion',
      },
      APP_SCREEN: {
        button_show_banner: 'Afficher',
      },
      LEGAL_SCREEN: {
        
      }
    }
  },
  fr: {
    translation: {
      HOME_SCREEN: {
        HELLO_APP: 'Testing app',
        FAVORIS_TITLE: 'Favoris',
        HISTORY_TITLE: 'Mes sites',
        EMPTY_HISTORY_:
          "Pour le moment, il n'y a pas de site. Creer votre 1er site pour commencer.",
        LOGOUT_BUTTON: 'Deconnexion',
      },
      APP_SCREEN: {
        button_show_banner: 'Afficher',
        price: {
          text: 'Prix',
          button1: 'Gratuit',
          button2: 'Payant',
        },
        legal_notice: 'Mentions légales',
        privacy_policy: 'Politique de confidentialité',
        categorie: '1:Jeux, 2:Réseaux sociaux, 3:Divertissement, 4:Musique, 5:Photo et vidéo, 6:Utilitaires, 7:Santé et fitness, 8:Productivité, 9:Voyage, 10:Éducation, 11:Finance, 12:Shopping, 13:Livres, 14:Actualités, 15:Sports, 16:Lifestyle',
      },
      LEGAL_SCREEN: {

      }
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    // the translations
   //ln: 'fr',
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
